import request from '../../../utils/request'

//根据查询条件获取项目列表
export function operationList(smartHomeName,limit,page,token) {
    return request({
        url:"/hotel/smartHomeDevice/getSmartHomeDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            smartHomeName:smartHomeName,
            limit:limit,
            page:page,
        }
    })
}
//根据查询条件项目设备详情
export function getDetail(smartHomeId,deviceStatus,token) {
    return request({
        url:"/hotel/smartHomeDevice/getSmartHomeDeviceDetails",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            smartHomeId:smartHomeId,
            deviceStatus:deviceStatus,
        }
    })
}