<template>
    <div style="height: 100%;position:relative;">
        <!--搜索区域-->
        <div class="header">
            <div style="color:#409EFF">
                项目名称：
                <el-input v-model="smartHomeName" size="medium" placeholder="请输入内容"></el-input>
            </div>
            <!--<div style="margin:0 10px;color:#409EFF">-->
                <!--网关状态：-->
                <!--<el-select v-model="gwStatus" placeholder="请选择">-->
                    <!--<el-option-->
                            <!--v-for="item in getwayStatus"-->
                            <!--:key="item.value"-->
                            <!--:label="item.label"-->
                            <!--:value="item.value">-->
                    <!--</el-option>-->
                <!--</el-select>-->
            <!--</div>-->
            <el-button type="primary" icon="el-icon-search" @click="getOperationList()">搜索</el-button>
        </div>
        <div class="wrapper">
            <el-table :data="operationData" style="width: 100%">
                <el-table-column type="index" width="50"></el-table-column>
                <el-table-column prop="smartHomeName" label="项目名称"></el-table-column>
                <!--<el-table-column prop="gwStatus" label="网关状态" width="200">-->
                    <!--<template scope="scope">-->
                        <!--<span style="border:1px solid #666;color:#666;background:#ddd;padding: 0 5px;border-radius: 3px;"  v-if="scope.row.gwStatus==='0'">离线</span>-->
                        <!--<span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.gwStatus==='1'">在线</span>-->
                    <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column prop="onlineCount" label="在线设备数量" width="200"></el-table-column>
                <el-table-column prop="offlineCount" label="离线设备数量" width="200"></el-table-column>

                <el-table-column label="详情">
                    <template slot-scope="scope">
                        <div class="operate">
                            <span @click="handleDetail(scope.row)" style="cursor : pointer;">查看</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--分页-->
        <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>

        <!--详情对话框-->
        <el-dialog title="详情" :visible.sync="dialogVisible" width="55%">
            <div style="line-height: 40px;">
                <p style="float:left;margin-right:30px;">项目名称：{{projectName}}</p>
                <div style="float:left;">
                    <span>设备状态：</span>
                    <el-select v-model="deviceStatus" placeholder="请选择">
                        <el-option v-for="item in roomStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
                <el-button type="primary" icon="el-icon-search" style="float:right;"  @click="getDetailInfo()">搜索</el-button>
            </div>
            <el-table :data="deviceList" style="width: 100%" height="350">
                <el-table-column prop="deviceName"  label="设备名称"></el-table-column>
                <el-table-column  prop="deviceId" label="设备ID" ></el-table-column>
                <el-table-column prop="deviceStatus" label="设备状态" width="100">
                    <template scope="scope">
                        <span style="border:1px solid #666;color:#666;background:#ddd;padding: 0 5px;border-radius: 3px;"  v-if="scope.row.deviceStatus===0">离线</span>
                        <span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.deviceStatus===1">在线</span>
                    </template>
                </el-table-column>
                <el-table-column prop="lastOnlineTime" label="最后上线时间"></el-table-column>
            </el-table>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import Mypage from '../../../components/MyPagination.vue'
    import {operationList,getDetail} from './operation'
    export default {
        components: {
            Mypage
        },
        name: "SmartHomeOperation",
        data() {
            return {
                dialogVisible: false, //打开新增模态框
                // getwayStatus: [
                //     {
                //         value: '',
                //         label: '请选择'
                //     },{
                //         value: '0',
                //         label: '离线'
                //     }, {
                //         value: '1',
                //         label: '在线'
                //     }],
                // gwStatus:'',
                smartHomeName:'',
                roomName:'',
                total:10,
                limit: 10,
                page: 1,
                operationData: [],
                roomStatus:[{
                    value: '',
                    label: '全部'
                },{
                    value: '0',
                    label: '离线'
                }, {
                    value: '1',
                    label: '在线'
                }],
                deviceStatus:'',
                projectName:'',
                smartHomeId:'',
                deviceList:[]
            }
        },
        methods: {
            //    查询酒店列表
            getOperationList() {
                operationList(this.smartHomeName,this.limit,this.page,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.operationData=result["data"]["data"]["records"]
                        this.total=parseInt(result["data"]["data"]["total"])
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //详情
            handleDetail(row){
                console.log('row',row)
                this.dialogVisible=true
                this.projectName=row.smartHomeName
                this.smartHomeId=row.smartHomeId
                this.getDetailInfo()

            },
            getDetailInfo(){
                getDetail(this.smartHomeId,this.deviceStatus, this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.deviceList=result["data"]["data"]
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //    分页
            changePage(num){
                this.page=num;
                this.getOperationList()
            }
        },
        created() {  /*生命周期函数*/
            this.getOperationList();
        }
    }
</script>

<style scoped>
    .header {
        display: flex;
    }

    .el-input {
        width: 200px;
        margin: 0 10px;
    }

    .el-button {
        padding: 10px 20px;
    }

    .wrapper {
        margin-top: 20px;
        text-align: center;
    }

    .operate span {
        color: #276EF1;
        margin: 0 5px;
    }

    ::v-deep .el-table th, ::v-deep .el-table td {
        text-align: center !important;
    }

    ::v-deep .el-form-item__label {
        width: 100px
    }
    ::v-deep .el-dialog__header {
        border-bottom:1px solid #ddd;
    }

</style>